export const ConvertToWebp = {
  methods: {
    async convertToWebp(imageURL, quality = 0.7) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "Anonymous"; // Allow cross-origin images if necessary
        img.onload = () => {
          // Create a canvas
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          // Set canvas size to match image
          canvas.width = img.width;
          canvas.height = img.height;

          // Draw the image on the canvas
          ctx.drawImage(img, 0, 0);

          // Convert the canvas content to WebP data URL with specified quality
          const webpDataURL = canvas.toDataURL("image/webp", quality); // Quality is from 0.0 to 1.0

          // Convert the WebP data URL to File
          const [header, base64String] = webpDataURL.split(",");
          const mimeType = header.match(/:(.*?);/)[1];

          // Decode base64 string to binary
          const byteCharacters = atob(base64String);
          const byteNumbers = new Array(byteCharacters.length);

          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);

          // Create a Blob from the byte array
          const blob = new Blob([byteArray], { type: mimeType });

          // Replace the original extension with ".webp" for the file name
          const webpFileName = decodeURI(imageURL)
            .split("/")
            .pop()
            .replace(/\.[^/.]+$/, ".webp");

          // Create a File object from the Blob
          const webpFile = new File([blob], webpFileName, { type: blob.type });

          // Resolve the promise with the File object
          resolve(webpFile);
        };

        img.onerror = (err) => reject(err);

        // Set the image source
        img.src = imageURL;
      });

      return webpFile;
    },
  },
};
