<template>
  <h2 class="hidden">Please try in larger screens</h2>
  <div
    v-if="!isAuthenticated && !$route.meta.requiresAuth"
    className="adminDashboard"
  >
    <router-view />
  </div>
  <div v-else className="adminDashboard">
    <SideBar
      v-if="$route.meta.requiresAuth"
      :sidebarSizeChanged="handleSidebarSizeChanged"
    />
    <div class="routerView" :class="{ sidebarSmall: isSidebarSmall }">
      <Profile v-if="$route.meta.requiresAuth" />
      <router-view />
    </div>
  </div>
</template>

<script>
import { loadRouteLocation } from "vue-router";
import SideBar from "./components/AdminSideBar/SideBar.vue";
import Profile from "./components/AdminWidgets/Profile.vue";

export default {
  name: "App",
  components: {
    SideBar,
    loadRouteLocation,
    Profile,
  },
  data() {
    return {
      isSidebarSmall: false,
    };
  },
  setup() {
    const isAuthenticated = localStorage.getItem("token");
    return {
      isAuthenticated,
    };
  },
  methods: {
    handleSidebarSizeChanged(isHidden) {
      this.isSidebarSmall = isHidden;
    },
    // checkAuthentication() {
    //   if (!this.isAuthenticated) {
    //     console.log("route===========", this.$route);
    //     this.$router.push("/login"); // Redirect to login if not authenticated
    //   }
    // },
  },
  // beforeMount() {
  //   this.checkAuthentication(); // Check authentication on initial mount
  // },
};
</script>

<style lang="scss">
.adminDashboard {
  display: flex;
  flex-direction: row;

  @media (max-width: 1000px) {
    display: none;
  }

  .routerView {
    width: 100%;
    overflow-x: hidden;

    .main-Conntainer-admin {
      width: 85%;
      margin-left: auto;
      padding: 0 15px 0 0;
    }
  }

  .sidebarSmall {
    width: 100%;

    .main-Conntainer-admin {
      width: 95.5%;
      // margin-left: auto;
      margin: 0 5%;
    }
  }
}

.hidden {
  display: none;

  @media (max-width: 1000px) {
    display: flex;
    justify-content: center;
    margin: 12rem;
    align-items: center;
  }
}
</style>
