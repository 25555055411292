import axios from "axios";
import router, { openRoutes } from "@/router";
import { userToken } from "../../src/services/util";

class api_request {
  constructor() {
    this.endpoint = process.env.VUE_APP_API;
  }

  async sendRequest(json, files = []) {
    const token = userToken();
    if (!token && !openRoutes.includes(window.location.pathname)) {
      localStorage.clear();
      sessionStorage.clear();
      router.push("/login");
      return;
    }

    const data = new FormData();
    data.append("request", JSON.stringify(json));

    if (Array.isArray(files)) {
      files.forEach((file) => {
        data.append("files", file);
      });
    } else if (files) {
      data.append("files", files);
    }

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: this.endpoint,
      headers: {
        // ...data.getHeaders(),
        Authorization: `Bearer ${token}`,
        "Content-type": "multipart/form-data",
      },
      data: data,
    };

    return axios
      .request(config)
      .then(async (response) => {
        const resp = response.data;
        // console.log(resp);
        if (resp.data?.[0].status === 403 || resp.data?.[0].status === 401) {
          localStorage.clear();
          sessionStorage.clear();
          window.location.href = "/login";
          return;
        } else {
          return resp;
        }
      })
      .catch((error) => {
        if (
          (error.response && error.response.status === 403) ||
          error.response.status === 401
        ) {
          localStorage.clear();
          sessionStorage.clear();
          window.location.href = "/login";
        }
        return error;
      });
  }
}

export default api_request;
