<script setup>
import { defineProps, defineEmits, ref, watch, onMounted } from "vue";

// Define props
const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  modelValue: {
    type: [String, Array],
  },
  label: {
    type: String,
    default: "Select an option",
  },
  required: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Array,
    default: () => [],
  },
  allowMultiple: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
  },
  defaultValue: {
    type: String,
    default: "",
  },
  isGlobal: {
    type: Boolean,
  },
});

// Emit event to update modelValue
const emit = defineEmits(["update:modelValue"]);

// Initialize selectedValue based on allowMultiple, modelValue, or defaultValue
const selectedValue = ref(
  props.allowMultiple
    ? Array.isArray(props.modelValue) && props.modelValue.length
      ? props.modelValue
      : [props.defaultValue]
    : props.modelValue || props.defaultValue || ""
);

// Update selectedValue on component mount
onMounted(() => {
  if (!selectedValue.value && !props.allowMultiple) {
    selectedValue.value = "";
  }
  emit("update:modelValue", selectedValue.value);
});

// Watch for changes in modelValue and update selectedValue accordingly
watch(
  () => props.modelValue,
  (newValue) => {
    selectedValue.value = props.allowMultiple
      ? Array.isArray(newValue) && newValue.length
        ? newValue
        : [props.defaultValue]
      : newValue || props.defaultValue || "";
  },
  { immediate: true }
);

// Watch selectedValue and emit the correct type based on allowMultiple
watch(selectedValue, (newValue) => {
  emit("update:modelValue", newValue);
});

// Handle change event to update selectedValue correctly
const handleChange = (event) => {
  const select = event.target;
  selectedValue.value = props.allowMultiple
    ? Array.from(select.selectedOptions).map((option) => option.value)
    : select.value;
};
</script>


<template>
  <div class="select-preview">
    <label :for="id">
      {{ label }}
      <span class="required" v-if="required">(Required)</span>
    </label>
    <select
      :id="id"
      :multiple="allowMultiple"
      @change="handleChange"
      :required="required"
      v-model="selectedValue"
      :disabled="isGlobal"
    >
      <!-- Placeholder option (conditionally shown) -->
      <option value="" disabled :selected="!selectedValue">
        {{ props.placeholder || "Choose here" }}
      </option>
      
      <!-- Dynamic options -->
      <option
        v-for="option in options"
        :key="option"
        :value="option"
      >
        {{ option }}
      </option>
    </select>
  </div>
</template>


<style lang="scss" scoped>
.select-preview {
  width: 18vw;
}
</style>
