class licenseValidator {
  constructor(userData, mainModuleName) {
    try {
      this.userData = userData;
      this.mainModuleName = mainModuleName;
      this.mainModule = this.findModuleByName(
        this.userData.licenses,
        mainModuleName
      );
    } catch (error) {
      console.log("Error in validation: Unauthorized!");
    }
  }

  findModuleByName(modules, moduleName) {
    try {
      for (const module of modules) {
        if (module.moduleName === moduleName) {
          return module;
        }
      }
      return false;
    } catch (error) {
      console.log("Error in validation: Unauthorized!");
    }
  }

  hasReadPermission(subModule) {
    try {
      const mySubModule = this.mainModule.permissions.find(
        (el) => el.subModuleName === subModule
      );
      if (mySubModule) {
        return mySubModule.read;
      }
      return false;
    } catch (error) {
      console.log("Error in validation: Unauthorized!");
    }
  }

  hasCreatePermission(subModule) {
    try {
      const mySubModule = this.mainModule.permissions.find(
        (el) => el.subModuleName === subModule
      );
      if (mySubModule) {
        return mySubModule.create;
      }
      return false;
    } catch (error) {
      console.log("Error in validation: Unauthorized!");
    }
  }

  hasUpdatePermission(subModule) {
    try {
      const mySubModule = this.mainModule.permissions.find(
        (el) => el.subModuleName === subModule
      );
      if (mySubModule) {
        return mySubModule.update;
      }
      return false;
    } catch (error) {
      console.log("Error in validation: Unauthorized!");
    }
  }

  hasDeletePermission(subModule) {
    try {
      const mySubModule = this.mainModule.permissions.find(
        (el) => el.subModuleName === subModule
      );
      if (mySubModule) {
        return mySubModule.delete;
      }
      return false;
    } catch (error) {
      console.log("Error in validation: Unauthorized!");
    }
  }
}

export default licenseValidator;
