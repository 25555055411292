<script setup>
import GenericContainer from "./GenericContainer.vue";
import DynamicComponent from "./DynamicComponent.vue";
import { onMounted, ref, computed, toRaw } from "vue";
import { useRoute } from "vue-router";
import Button from "@/components/AdminWidgets/Button.vue";
import ContentBox from "@/components/ContentBox/ContentBox.vue";
import { useStore } from "vuex";
import EditComponent from "@/components/ContentBox/EditComponent.vue";
import { useToast } from "vue-toast-notification";

const props = defineProps({
  data: Object,
  isCollection: Boolean,
  isGlobalComponent: Boolean,
  Index: Number,
  maxlength: Number,
  parentindex: Number,
  ischild: Boolean,
  childdata: Array,
});
const toast = useToast();

const compName = ref(props.data.componentName + " Update");

onMounted(() => {
  if (props.data.childComponents && props.data.childComponents.length === 0) {
    isOpen.value = true;
  }
});

const showModal = ref(false);
const isEditModalOpen = ref(false);

const closeEditModal = () => {
  isEditModalOpen.value = false;
};

async function toggleEditModal() {
  isEditModalOpen.value = !isEditModalOpen.value;
}

function checkType(value) {
  if (value == "Components" || value == "Collections") {
    return DynamicComponent; // Return the component object
  } else if (value != "Components") {
    return GenericContainer; // Return the component object
  } else {
    return null; // Optionally handle other cases or return null
  }
}

const store = useStore();
const items = computed(() => store.getters.items);

// Reactive states
const isOpen = ref(false);
const isAddFieldModalOpen = ref(false);

// Function to toggle the component
const toggleComponent = () => {
  isOpen.value = !isOpen.value;
};

// function to open add field modal
const handleToggleAddField = () => {
  isAddFieldModalOpen.value = !isAddFieldModalOpen.value;
};

const deleteComponent = async (id, data) => {
  const swalRes = await swal({
    title: "Continue to delete?",
    icon: "warning",
    buttons: true,
  });

  if (swalRes) {
    try {
      let obj = items.value[0];
      obj = await deleteComponentById(obj, id);
      store.dispatch("updateItem", { index: 0, newItem: obj });
      if(data?.componentName == "Collections"){
        toast.success("The Collection has been deleted.");
      }
      else{
        toast.success("The component has been deleted.");
      }
    } catch {
      toast.error("Something went wrong.");
    }
  }
};

const deleteComponentById = async (dataObject, idToDelete) => {
  // Function to recursively search and delete by id
  const recursiveDelete = (components) => {
    return components.filter((component) => {
      // Check if the component has childComponents and recursively search
      if (component.childComponents) {
        component.childComponents = recursiveDelete(component.childComponents);
      }
      // Return false if the id matches, to filter out the object
      return component.id !== idToDelete;
    });
  };

  // Start the deletion from componentData array
  dataObject.componentData = recursiveDelete(dataObject.componentData);
  return dataObject;
};

const openComponentModal = async () => {
  showModal.value = !showModal.value;
  // if(!showModal.value) {
  //   // let temp = items.value[0].componentData;
  //   // console.log(items.value[items.value.length - 1])
  //   // await addComponentById(temp, props.data.id, items.value[items.value.length - 1]);
  //   // let temp2 = items.value[0];
  //   // temp2.componentData = temp;
  //   // store.dispatch("updateItem", {index: 0, newItem: temp2})
  //   await submitData();
  // }
};

const submitData = async () => {
  let temp = items.value[0].componentData;
  await addComponentById(
    temp,
    props.data.id,
    items.value[items.value.length - 1]
  );
  let temp2 = items.value[0];
  temp2.componentData = temp;
  store.dispatch("updateItem", { index: 0, newItem: temp2 });

  isOpen.value = true;
};

async function addToArray(arr, name, newElement) {
  let targetArray = [];
  // Helper function to search for the array with the matching name
  function findArray(arr, name) {
    for (let i = 0; i < arr.length; i++) {
      if (Array.isArray(arr[i])) {
        // Check if the first element is the name we are looking for
        if (typeof arr[i][0] === "string" && arr[i][0] === name) {
          return arr[i]; // Return the array if found
        }
        // Recursively search nested arrays
        const nestedResult = findArray(arr[i], name);
        if (nestedResult) {
          return nestedResult;
        }
      }
    }
    return null; // Return null if the array with the name is not found
  }

  // Find the target array using the helper function
  if (arr && arr.length) {
    targetArray = findArray(arr, name);
  }

  if (targetArray) {
    targetArray.push(newElement); // Add the new element (array or object) to the target array
  } else {
    console.error("Array with the given name not found.");
  }
}

async function addComponentById(components, targetId, newComponent) {
  // Recursive helper function to search for the target id
  function findAndAdd(componentsList) {
    for (let component of componentsList) {
      // If the current component matches the target id, add the new component
      if (component.id === targetId) {
        if (!component.childComponents) {
          component.childComponents = []; // Initialize if not present
        }
        component.childComponents.push(newComponent);
        return true; // Return true if the component was added
      }

      // If the component has childComponents, search recursively
      if (component.childComponents && component.childComponents.length > 0) {
        const found = findAndAdd(component.childComponents);
        if (found) return true;
      }
    }
    return false; // Return false if the component with the target id wasn't found
  }

  return findAndAdd(components);
}
const findComponentById = (data, id) => {
  for (let i = 0; i < data.length; i++) {
    if (data[i].id === id) {
      return { parentArray: data, currentIndex: i };
    }
    if (data[i].childComponents) {
      const result = findComponentById(data[i].childComponents, id);
      if (result) return result;
    }
  }
  return null;
};
const moveComponentDownById = (componentData, id) => {
  const updatedData = [...items.value[0].componentData];

  // Find the target component and its parent array
  const target = findComponentById(updatedData, id);

  if (!target) {
    toast.error("Component not found");
    return;
  }

  const { parentArray, currentIndex } = target;

  // Check if moving down is possible
  if (currentIndex >= parentArray.length - 1) {
    toast.error("Down Arrow Is Not Accessible");
    return;
  }

  // Swap components
  const temp = parentArray[currentIndex];
  parentArray[currentIndex] = parentArray[currentIndex + 1];
  parentArray[currentIndex + 1] = temp;

  // Dispatch the updated structure
  store.dispatch("sortItems", { index: 0, newItem: updatedData });
};

const moveComponentUpById = (componentData, id) => {
  const updatedData = [...items.value[0].componentData];

  // Find the target component and its parent array
  const target = findComponentById(updatedData, id);

  if (!target) {
    toast.error("Component not found");
    return;
  }

  const { parentArray, currentIndex } = target;

  // Check if moving up is possible
  if (currentIndex <= 0) {
    toast.error("Up Arrow Is Not Accessible");
    return;
  }

  // Swap components
  const temp = parentArray[currentIndex];
  parentArray[currentIndex] = parentArray[currentIndex - 1];
  parentArray[currentIndex - 1] = temp;

  // Dispatch the updated structure
  store.dispatch("sortItems", { index: 0, newItem: updatedData });
};
</script>

<template>
  <!-- <div class="dynamicParent">
        <h1>{{ data[0] }}</h1>
        <component v-for="(d, index) in data" :key="index" :is="checkType(d)" :data="d"/>
    </div> -->
  <div class="main-frame" v-if="!data.replicated">
    <div class="container-1">
      <div class="item">
        <section>
          <div class="body-container">
            <div class="component-container">
              <span
                class="toggle"
                @click="toggleComponent"
                :class="{ rotated: isOpen }"
              >
                <v-icon class="icon" name="bi-caret-right-fill"></v-icon
              ></span>
              <div class="generic-parent">
                <div class="leftComp">
                  <!-- <img src="@/assets/radix-icons_component-13.svg"/> -->
                  <img src="@/assets/radix-icons_component-13.svg" />
                  <span class="contentType"> {{ props.data.label }} </span>
                </div>
                <span class="componentParentSpan">
                  {{ props.data.componentName }}
                </span>
                <!-- <span class="internalizationParentSpan">
                  English(US), English(UK)</span
                > -->
                <span class="actionSpan">
                  <v-icon
                    class="icon-style"
                    name="la-edit-solid"
                    @click="toggleEditModal"
                    v-if="!props.isCollection && !props.isGlobalComponent"
                  />
                  <v-icon
                    class="trashIcon icon-style"
                    @click="deleteComponent(props.data.id, props.data)"
                    name="fa-trash-alt"
                    v-if="
                      (!props.isCollection && !props.isGlobalComponent) ||
                      (props.isGlobalComponent && props.data.isGlobal) ||
                      props.data.componentName == 'Collections'
                    "
                  />
                  <v-icon
                    class="icon-style"
                    name="bi-arrow-up"
                    @click="moveComponentUpById(props, props.Index)"
                    v-if="
                      (!props.isCollection && !props.isGlobalComponent) ||
                      (props.isGlobalComponent && props.data.isGlobal) ||
                      props.data.componentName == 'Collections'
                    "
                  />
                  <v-icon
                    name="bi-arrow-down"
                    @click="moveComponentDownById(props, props.Index)"
                    v-if="
                      (!props.isCollection && !props.isGlobalComponent) ||
                      (props.isGlobalComponent && props.data.isGlobal) ||
                      props.data.componentName == 'Collections'
                    "
                  />
                </span>
              </div>
            </div>
            <div class="subcomponent-container" v-if="isOpen">
              <div
                class="no-data"
                v-if="props.data.childComponents.length == 0"
              >
                <span>No Fields Available</span>
              </div>
              <!-- {{ data }} -->
              <component
                v-for="(d, index) in data.childComponents"
                :key="index"
                :is="checkType(d.componentName)"
                :data="d"
                :maxlength="items[0].componentData.length"
                :Index="d.id"
                :parentindex="Index"
                :ischild="true"
                :childdata="data.childComponents"
                :isCollection="props.isCollection"
                :isGlobalComponent="
                  props.isGlobalComponent
                    ? true
                    : d.componentName == 'Components' && d.isGlobal
                    ? true
                    : false
                "
              />
              <hr />
              <div
                class="addComponentBtn"
                v-if="!props.isCollection && !props.isGlobalComponent"
              >
                <button class="addComponentBtn" @click="openComponentModal">
                  <v-icon name="oi-plus-circle" scale="1.6" />
                </button>
                <span>
                  Add {{ props.data.childComponents.length ? "another" : "" }}
                  field to this component
                </span>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
  <ContentBox
    :isOpen="showModal"
    :close="openComponentModal"
    @handleFinishInParent="submitData"
    :header="data.label"
    source="component"
  />
  <EditComponent
    v-if="isEditModalOpen"
    :data="props.data"
    :component="compName"
    :isOpen="isEditModalOpen"
    :close="toggleEditModal"
  />
</template>

<style lang="scss" scoped>
hr {
  border: none;
  height: 1px;
  background-color: #606060;
  margin-bottom: 0;
}

.rotated {
  transform: rotate(90deg);
  transition: transform 0.3s ease-in-out;
}

.customContainer {
  width: 100%;
}

.item {
  section {
    width: 100%;
    margin: 0;
    color: #606060;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-sizing: border-box;

    .body-container {
      margin-top: 8px;

      .subcomponent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
      }
    }

    .component-container {
      border: 1px solid blue;
      border-radius: 10px;
      padding: 0.5rem 0rem;
      margin: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .generic-parent {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 2.5rem 1rem 1rem;
      padding: 0.5rem 3rem 0.5rem 0rem;
      width: 100%;
    }

    .leftComp {
      display: flex;
      align-items: center;

      .contentType {
        margin-left: 1rem;
      }
    }

    .subcomponent-container {
      border: 1px solid #0146f4;
      border-radius: 10px;
      margin: 1rem;

      .no-data {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
        font-weight: 400;
        color: #0146f4;
      }

      .component-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 0rem;

        .generic-parent {
          padding: 0.5rem 2rem 0.5rem 0rem;
        }
      }

      .subcomponent-container {
        .genericParent {
          padding: 1rem 1.5rem 1rem 0.5rem;
          margin: 1rem 0.5rem 1rem 0.5rem;

          .componentNameSpan {
            margin: 0rem;
          }
        }
      }
    }

    .addComponentBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem 0 0.5rem 0;

      button {
        background-color: transparent;
        border: none;
        color: #606060;
        cursor: pointer;
        margin-right: 10px;

        &:hover {
          background-color: transparent !important;
        }
      }

      span {
        font-size: 1rem;
        color: #606060;
        font-weight: 600;
      }

      button:hover {
        background-color: #f0f0f0;
      }
    }
  }
}

.subcomponent-container .component-container {
  border: 1px solid #0146f4;
  margin: 0rem 1rem 0rem 1rem;
}

.leftComp {
  flex: 4;

  .contentType {
    margin-left: 1rem;
  }
}

.componentParentSpan {
  flex: 2;
  text-align: center;
}

.internalizationParentSpan {
  flex: 3;
  text-align: center;
}

.actionSpan {
  flex: 1.1;
  text-align: center;
}

.trashIcon {
  color: red;
}

.rotated {
  transform: rotate(90deg);
  transition: transform 0.3s ease;
}


.icon-style{
  margin-right: 1.2vh;
}
</style>
