<script setup>
import { ref, defineExpose, onMounted } from "vue";

// Define props
const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
    default: "Name",
  },
  disabled: {
    type: Boolean,
    default: false
  },
  type: {
    type: String,
    default: "text",
  },
  isChecked: {
    type: Boolean,
    default: false,
  },
  width: {
    type: String,
  },
  placeholder: {
    type: String,
    default: "",
  },
  description: {
    type: String,
    default: "",
  },
  data: {
    type: Object,
  },
  required: {
    type: Boolean,
    default: false,
  }
});

// Reactive value based on prop
const isChecked = ref(props.isChecked);

// Method to update the reactive value
const updateValue = (event) => {
  isChecked.value = event.target.checked;
};

async function resolveValue() {
  if(props.data)
  {
    if(props.label == "Required Field")
      isChecked.value = props.data.isRequired || false;
    if(props.label == "Unique Field")
      isChecked.value = props.data.isUnique || false;
    if(props.label == "Private Field")
      isChecked.value = props.data.isPrivate || false;
    if(props.label == "Enable Localization for this field")
      isChecked.value = props.data.isLocalization || false;
    if (props.label == "Show Component Itself")
      isChecked.value = props.data.showComponentItself || false;
    if (props.label == "Allow Multiple")
      isChecked.value = props.data.allowMultiple || props.data.multiple || false;
  }
}

// Expose getData method
defineExpose({
  getData: () => {
    return { isChecked: isChecked.value };
  },
});

onMounted(async () => {
  await resolveValue();
})
</script>

<template>
  <div class="formComponentBody">
    <div class="checkboxBody" :style="{ width: props.width }">
      <div class="headingAndCheckbox">
        <input
          type="checkbox"
          class="checkbox item"
          :checked="isChecked"
          :disabled="disabled"
          @change="updateValue"
          :required="props.required"
        />
        <div class="formComponent_Title item">{{ props.label }}</div>
      </div>
      <p class="descriptionText">{{ props.description }}</p>
    </div>
  </div>
</template>

<style scoped>
.item {
  display: inline-block;
  margin: 10px;
}

.checkbox {
  width: auto;
  padding: 0;
  margin: 0;
}

.formComponentBody .descriptionText {
  font-size: 0.75rem;
  font-weight: 400;
  color: #606060;
}

.formComponent_Title {
  padding-left: 10px;
}

.checkboxBody {
  padding: 10px;
  padding-top: 0;
}
</style>
