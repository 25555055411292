<template>
  <div class="spline-line-chart">
    <apexchart
      :options="chartOptions"
      :series="data"
      :height="height"
      :stroke="stroke"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import { GlobalMixin } from "@/mixins/global.mixin";

export default {
  name: "LineChart",
  mixins: [GlobalMixin],
  props: {
    height: {
      type: Number,
      default: 300,
    },
    stroke: {
      type: Number,
      default: 3,
    },
    data: {
      type: Array,
      required: true,
    },
    dataType: {
      type: String,
      required: true,
    },
  },
  components: {
    apexchart: VueApexCharts,
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          id: 'Unique_Visitors_and_Page_Views',
          type: "line",
          zoom: {
            enabled: false,
          },
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
        },

        stroke: {
          width: this.stroke,
        },
        series: this.data,

        grid: {
          borderColor: "#BDBDBD",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.3,
          },
        },

        markers: {
          size: 7,
        },
        legend: {
          position: "bottom",
          horizontalAlign: "center",
        },
        xaxis: {
          categories: this.generateXLabels(), // Use the generated x-axis labels
        },
        title: {
          text: "Unique Visitors and Page Views", // Initially no title
          align: "left", // Default alignment
          offsetX: 8, // Horizontal offset
          offsetY: -4,
          style: {
            fontSize: "18px",
            fontWeight: "bold",
            color: "#0146f4",
          },
        },
      };
    },
  },
  methods: {
    generateXLabels() {
      const today = new Date();
      let labels = [];

      if (this.dataType === "Weekly") {
        for (let i = 6; i >= 0; i--) {
          labels.push(
            this.formatDate(
              new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate() - i
              )
            )
          );
        }
      } else if (this.dataType === "Monthly") {
        for (let i = 9; i >= 0; i--) {
          labels.push(
            this.formatDate(
              new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate() - i * 3
              )
            )
          );
        }
      } else if (this.dataType === "Yearly") {
        for (let i = 11; i >= 0; i--) {
          let date = new Date(today.getFullYear(), today.getMonth() - i, 1);
          labels.push(this.formatMonthYear(date));
        }
      }

      return labels;
    },
    formatMonthYear(date) {
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const month = monthNames[date.getMonth()];
      const year = date.getFullYear();
      return `${month} ${year}`;
    },
  },
};
</script>

<style scoped>
.spline-line-chart {
  width: 98%;
  margin: 0 auto;
  padding-top: 20px;
}

::v-deep .apexcharts-toolbar {
  top: -8px !important; /* Adjust 'top' as needed and use '!important' to override existing styles */
}
</style>
