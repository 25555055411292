<template>
  <div class="spline-line-chart">
    <apexchart
      :options="chartOptions"
      :series="data"
      :height="height"
      :stroke="stroke"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import { GlobalMixin } from "@/mixins/global.mixin";


export default {
  name: "SplineLineChart",
  mixins: [GlobalMixin],

  props: {
    height: {
      type: Number,
      default: 300,
    },
    stroke: {
      type: Number,
      default: 3,
    },
    data: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: "Spline Line Chart",
    },
    dataType: {
      type: String,
      required: true,
    },
  },
  components: {
    apexchart: VueApexCharts,
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: "line",
          id: 'Resource_Views',
          zoom: {
            enabled: false,
          },
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
        },

        stroke: {
          curve: "smooth",
          width: this.stroke,
        },
        series: this.data,
        xaxis: {
          categories: this.generateXLabels(), // Use the generated x-axis labels
        },
        title: {
          text: "Resource Views", // Initially no title
          align: "left", // Default alignment
          offsetX: 8, // Horizontal offset
          offsetY: -4,
          style: {
            fontSize: "18px",
            fontWeight: "bold",
            color: "#0146f4",
          },
        },

        grid: {
          borderColor: "#BDBDBD",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.3,
          },
        },

        markers: {
          discrete: [
            {
              seriesIndex: 0,
              dataPointIndex: this.findMaxDataPointIndex(0),
              fillColor: this.data[0].color,
              strokeColor: this.data[0].color,
              strokeOpacity: 0.4,
              size: 6,
              shape: "circle", // "circle" | "square" | "rect"
            },
          ],
        },
        legend: {
          position: "bottom",
          horizontalAlign: "center",
        },
        dataLabels: {
          enabled: true,
          offsetY: -10,
          borderRadius: 50,

          formatter: function (value, { seriesIndex, dataPointIndex, w }) {
            const series = w.globals.series[seriesIndex];
            if (value === Math.max(...series)) {
              return value.toFixed(2); // Show label only for highest value
            } else {
              return ""; // Hide label for other data points
            }
          },
        },
      };
    },
  },
  methods: {
    findMaxDataPointIndex(index) {
      let maxIndex = 0;
      let maxValue = -Infinity;
      const data = this.data[index].data;

      for (let i = 0; i < data.length; i++) {
        if (data[i] > maxValue) {
          maxValue = data[i];
          maxIndex = i;
        }
      }
      return maxIndex;
    },
    generateXLabels() {
      const today = new Date();
      let labels = [];

      if (this.dataType === "Weekly") {
        for (let i = 6; i >= 0; i--) {
          labels.push(
            this.formatDate(
              new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate() - i
              )
            )
          );
        }
      } else if (this.dataType === "Monthly") {
        for (let i = 9; i >= 0; i--) {
          labels.push(
            this.formatDate(
              new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate() - i * 3
              )
            )
          );
        }
      } else if (this.dataType === "Yearly") {
        for (let i = 11; i >= 0; i--) {
          let date = new Date(today.getFullYear(), today.getMonth() - i, 1);
          labels.push(this.formatMonthYear(date));
        }
      }

      return labels;
    },
    formatMonthYear(date) {
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const month = monthNames[date.getMonth()];
      const year = date.getFullYear();
      return `${month} ${year}`;
    },
  },
};
</script>

<style scoped lang="scss">
.spline-line-chart {
  width: 100%;
}

::v-deep .apexcharts-toolbar {
  top: -10px !important; /* Adjust 'top' as needed and use '!important' to override existing styles */
}
</style>
