<script setup>
import { GlobalMixin } from "@/mixins/global.mixin";

const props = defineProps({
  img: String,
  name: String,
  id: String,
  date: String,
  click: Function,
  checkBoxClick: Function,
  selected: Boolean,
  deletePermission: Boolean,
});

const handleClick = () => {
  props.click(props.id);
};

const handleCheckBoxClick = (event) => {
  event.stopPropagation();
  props.checkBoxClick(true, props.id, event.target.checked);
};

const getFilePreviewImageName = () => {
  const fileExtensions = {
    pdf: "vi-file-type-pdf2",
    txt: "vi-file-type-text",
    zip: "vi-file-type-zip",
    default: "vi-default-file",
  };

  const extension = props.name.split(".").pop().toLowerCase();

  return fileExtensions[extension] || fileExtensions.default;
};

const isPreviewPossible = () => {
  const extension = props.name.split(".").pop().toLowerCase();
  
  if(extension === "jpg" || extension === "png" || extension === 'jpeg' || extension === 'webp') {
    return true;
  }

  return false;
}
</script>
<template>
  <div class="file-card">
    <div class="file-img" @click="handleClick">

      <img v-if="isPreviewPossible()"
          class="file-preview"
          :src="img"
          alt=""
      />
      <v-icon v-if="!isPreviewPossible()"
          class="file-preview"
          :name="getFilePreviewImageName()"
          scale="10"
      />
      
      <input
        v-if="deletePermission"
        :checked="selected"
        type="checkbox"
        name=""
        id=""
        v-on:click="handleCheckBoxClick($event)"
      />
    </div>
    <div class="file-stat">
      <div class="file-name">
        <span class="fileNameSpan" :title="name">{{
          name.length > 15 ? name.substring(0, 12) + "..." : name
        }}</span>
      </div>
      <span class="file-date">{{
        GlobalMixin.methods.formatDate(new Date(date))
      }}</span>
    </div>
    <div v-if="selected" class="selected"></div>
  </div>
</template>
<style scoped>
.selected {
  background-color: rgba(0, 119, 255, 0.233) !important;
  height: 100%;
  position: absolute;
  width: 100%;
}
.file-card {
  position: relative;
  width: 222px;
  text-align: start;
  color: #606060;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.3rem;
  align-items: start;
  max-width: fit-content;
  gap: 10px;
  -webkit-box-shadow: 0px 2px 10px -4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 2px 10px -4px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 10px -4px rgba(0, 0, 0, 0.2);
}
.file-preview {
  max-height: 100%; 
  max-width: 100%; 
  height: auto; 
  width: auto;
  object-fit: contain;
  transition: transform 0.2s ease;
}
.file-preview:hover {
  transform: scale(1.05);
}
.file-stat {
  padding-left: 0.5rem;
  margin-top: auto;
}
.file-date {
  font-size: small;
}
.file-stat > .file-name {
  font-size: medium;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
}
.file-img {
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 11rem; 
  overflow: hidden;
}
.file-img > div {
  height: 200px;
}
input[type="checkbox"] {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0.5rem;
  z-index: 1;
}
</style>
